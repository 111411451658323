/* nojquery *
export function handle() {
  // gestion dropdown sur la souris
  var menuDisplay = function (event) {
    console.log(this.parentNode.getElementsByClassName('dropdown-menu')[0])
    this.parentNode.getElementsByClassName('dropdown-menu')[0].classList.add('d-block')
  }
  var dT = document.querySelectorAll('.dropdown-toggle') // ou .getElementsByClassName('dropdown-toggle')
  dT.forEach(function (elem) {
    elem.addEventListener('mouseenter', menuDisplay, false)
    elem.addEventListener('click', menuDisplay, false)
    elem.addEventListener('touchstart', menuDisplay, false)
  })
}
*/

/**
 * Fonctionnalités principale au chargement d'une page
 */
export function handle() {
  // gestion dropdown sur la souris
  $('#header').find('.dropdown-toggle').on('mouseenter click', function () {
    $(this).parent().find('.dropdown-menu').first().addClass('d-block')
  })
//  $('nav.navbar li.dropdown.megamenu').on('mouseenter click', function(e) {
//    //const a = document.body.clientWidth
//    const a = $(window).width(),
//          i = 1199 - 40 // max viewport - scrollbar
//    if (a > i) {
//      const o = $(this).children('.dropdown-menu')
//      o.css({ left: '' }),
//        parseInt(o.css('padding-left'))
//        let n = $(this).position().left,
//            r = $(this).outerWidth(),
//            s = $('.navbar-collapse').offset().left,
//            l = o.outerWidth(),
//            d = n + r / 2 - l / 2,
//            c = a - s
//        d + s < 0 ? d = 30 - s : d + l > c && (d = c - l - 30),
//        o.css({
//          left: d + 'px'
//        })
//      } else
//        $(this).children('.dropdown-menu').css({ right: '', left: '' })
//  })
  // navbar toggler
  $('[data-trigger]').on('click', function () {
    $($(this).attr('data-trigger')).toggleClass('show')
    $('.navbar-overlay').toggleClass('visible')
    $('body').addClass('open')
  })
  $('.navbar-close, .navbar-overlay').click(function () {
    $('.navbar-collapse').removeClass('show')
    $('.navbar-overlay').removeClass('visible')
    $('body').removeClass('open')
  })
}

/**
 * Sitcky header
 */
export function sticky () {
  const h = $('#header'),
        s = 'sticky',
//        t = 'fix',
        b = $('body'),
        w = $(window),
        bp = 1199 - 40

  if ((w.scrollTop() > 100 && w.width() > bp) || (w.scrollTop() > 0 && w.width() <= bp)) { // topbar présente
    h.addClass(s)
//    b.addClass(t)
  } else {
    h.removeClass(s)
//    b.removeClass(t)
  }
//  if (w.scrollTop() > 100) {
//    h.addClass(s)
//    b.addClass(t)
//  } else {
//    h.removeClass(s)
//    b.removeClass(t)
//  }
}
